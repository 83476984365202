export interface AnalyticsWindowContext extends Window {
    plausible?: any;
}

interface AnalyticsEvent {
    name: string;
    props: unknown;
}

const windowContext: AnalyticsWindowContext = window;

const trackEvent = async (event: AnalyticsEvent) => {
    try {
        if (windowContext?.plausible) {
            windowContext.plausible(event.name, {
                props: event.props,
            });
        }
    } catch {
        /* NOP */
    }
};

export const trackFileDownload = async (url?: string, filename?: string) =>
    trackEvent({
        name: "File Download",
        props: {
            url,
            filename,
        },
    });

export const trackMedia = async (type: "video" | "audio", source: string) =>
    trackEvent({
        name: "Media View",
        props: {
            type,
            source,
        },
    });

export const trackCTA = async (label: string, url: string) =>
    trackEvent({
        name: "CTA Link",
        props: {
            label,
            url,
        },
    });

export const trackSearch = async (query: string) =>
    trackEvent({
        name: "Site Search",
        props: {
            query,
        },
    });

export const trackCertificationQuestionnaireCompleted = async (resultUrl: string) =>
    trackEvent({
        name: "Certification Questionnaire Completed",
        props: {
            resultUrl,
        },
    });
